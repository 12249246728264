@charset "UTF-8";

@import "_variables";

.form {
	position: relative;

	.emailFix {
		display: none;
	}

	.inputWrapper {
		overflow: hidden;
	}

	.input {
		margin-bottom: 15px;

		label {
			display: block;
		}

		&.text input,
		&.password input,
		&.textarea textarea,
		&.select select {
			display: block;
			width: 100%;
			margin: 0;
			padding: 0 5px;
			background: #fff;
			border: 1px solid #9f9f9f;
			font-size: 13px;
			color: #000;
			height: 23px;
			line-height: 21px;

			-webkit-box-sizing: border-box;
			-moz-box-sizing: border-box;
			box-sizing: border-box;
		}

		&.select select {
			padding-left: 0;
		}

		&.textarea {
			width: 100%;
			clear: both;

			textarea {
				height: 100px;
				resize: none;
				padding: 5px;
			}
		}

		&.required {
			label {
				&:after {
					content: '*';
					color: $color-base;
					display: inline-block;
					font-weight: bold;
					margin-left: 3px;
				}
			}
		}
	}

	.submit {
		text-align: right;
	}

	.error-message {
		background: red;
		font-size: 12px;
		color: #fff;
		padding: 2px 5px;
	}
}

@each $placeholder in (':-webkit-input-placeholder', -moz-placeholder, ':-moz-placeholder', -ms-input-placeholder) {
	:#{$placeholder} {
		opacity: 1 !important;
	}
}
